<template>
  <user-form :initial-user="user" :title="title" :editing="true" />
</template>

<script>
import UserForm from "./UserForm.vue";

export default {
  name: "EditUser",

  components: {
    UserForm,
  },
  data() {
    return {
      title: "Edit User",
      user: this.$store.getters.userById(this.$route.params.id),
    };
  },
};
</script>
